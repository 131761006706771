// $primary: #a51616;
$primary: #FF7601; /* MAIN COLOR */
$secondary: #7CA9B2; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fff; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


@media (max-width: 1280px) {
	.container {max-width: 1100px;}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

body {padding: 0px;
	  margin: 0px;}

h1, h2, h3 {font-family: 'Fugaz One', cursive;

}

a {font-family: 'Raleway', sans-serif;}

p {font-family: 'Raleway', sans-serif;}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}


.navbar .navbar-nav {
    > li > a {
		text-align: center;
		align-items: center;
		font-size: 1.5em;
		
	    &:hover, &:focus {
	    	color: $secondary;
	    }
	}
}
 
.navbar-toggle {
    margin: 10px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}

	@media (max-width: 425px) {
		margin-top: 18px;
	}
}

.navbar-default {border-bottom: 3px solid $secondary;
				 box-shadow: 0px 2px 5px #333;
				 background-color: $wht;
				 border-radius: 0px !important;}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

/* =========== Navigation ============ */

.navbar-default .navbar-collapse {border-bottom: 0px;}

.navbar-brand {margin-top: 0px;
			   padding-top: 0px;
               }

.navbar-right {margin-top: 25px;

			   @media (max-width: 767px) {
				   margin-top: 0px;
				   margin-bottom: 0px;
			   }
			   @media (max-width: 1024px) {
				   margin-top: 10px;
			   }
			   @media (max-width: 1199px) and (orientation: landscape) {
				   margin-top: 15px;
			   }
}

.navbar-default .navbar-nav > li > a {font-size: 1.5em;
                                      font-family: 'Raleway', sans-serif;
                                      font-weight: bold;
                                      color: #ff7601;
                                      background-color: white;
                                      border-bottom: 0px !important;
									  border-radius: 0px;
}

.navbar-default .navbar-collapse {border: 0px;}



/* ---------------- HEADER ------------------- */

.header {background: url('../img/main_banner.png');
		 background-repeat: no-repeat;
		 background-position: 50% 100%;
		 background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		-webkit-background-size: cover; 

	@media (max-width: 1024px) {
		background-position: 40% 40%;
	}

	@media (max-width: 1199px) {
		background-position: 51% 50%;
	}
}

.header-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


	@media (max-width: 767px) {
		padding: 25px;
	}

	@media (max-width: 1024px) {
		padding: 15px;
	}
	@media (max-width: 1199px) {
		padding: 0px;
	}

}

.logo {width: 100%;
	   max-width: 292px;
	   
	   @media (max-width: 767px) {
		   width: 100%;
		   max-width: 250px;

	   }

	   @media (max-width: 1024px) {
		   max-width: 200px;
		   padding: 10px 10px 0px 0px;
	   }
	   @media (max-width: 1199px) and (orientation: landscape) {
				   margin-top: 10px;
		}
}



.logo-large img {top: 0px;  
    display: block;
    margin: 0 auto;
	width: 100%;
	max-width: 575px;

	@media (max-width: 767px) {
		width: 90%;

	}

	@media (max-width: 1024px) {
		width: 90%;

	}

	@media (max-width: 1200px) {
		width: 90%;

	}

	}

.logo-large h1 {color: $primary;
				text-align: center;
				line-height: 45px;
				margin-right: 0px;
				
				@media (max-width: 767px) {
					font-size: 1.75em !important;
					margin-right: 0px !important;
				} 

				@media (max-width: 1024px) {
					font-size: 2.5em;
					margin-bottom: 100px;
					margin-right: 0px !important;
				}	
					
	}


/* =========== Buttons ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color:$secondary;
    border-color: $wht;
	   color: $wht;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $primary;
    color: $secondary;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}


/* ------------- Form Area -------------- */

.form-area {background: url('../img/form-area-bg.png');
		 	background-repeat: no-repeat;
		 	background-position: 100% 40%;
		 	background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			-webkit-background-size: cover; 
			padding: 50px;

			@media (max-width: 767px) {
				padding: 15px;
				background-position: 80% 10%;

			}

		    @media (max-width: 1024px) {
				padding: 0px;
				background: none;
				text-align: center;
			}
		    @media (max-width: 1199px) {
				padding: 0px;
				background: none;
				text-align: left;
			}
			
	}

.form-area h1 {color: $primary;
			   font-size: 4em;
			   margin-bottom: 40px;

			   @media (max-width: 767px) {
				   font-size: 3em;
			   }

}

.form-area p {color: $secondary;
			  line-height: 35px;
			  font-size: 1.25em;
				
				@media (max-width: 767px) {
				   text-align: center;
			   }

				@media (max-width: 1024px) {
				   text-align: center;
			   }

}

.form-area h3 {color: $primary;
			   margin-bottom: 10px;

			   @media (max-width: 767px) {
				   text-align: center;
				   font-size: 1.5em;
			   }
			   @media (max-width: 1024px) {
				   text-align: center;
				   font-size: 2em;
			   }
}

.form-area img {padding-bottom: 0px;

				@media (max-width: 767px) {

					padding: 50px 0px;
				}

}

/* ------------- Features Area -------------- */

.features {
		  border-top: 15px solid $secondary;
		  border-bottom: 15px solid $secondary;
		  background: url('../img/feature-banner.jpg');
		 	background-repeat: no-repeat;
		 	background-position: 100% 60%;
		 	background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			-webkit-background-size: cover;}

.features-overlay {
    background-color: rgba(31, 31, 31, .35);
    padding: 25px;
	margin: 0px;

	@media (max-width: 1024px) {
		padding: 0px;
		text-align: center;
	}
    @media (max-width: 1199px) {
				padding: 0px;
				background: none;
				text-align: left;
				background-color: rgba(31, 31, 31, .55)
			}

}


.features h1 {color: $wht;
			  text-shadow: 2px 2px $primary;
			  font-size: 4em;
			  border-bottom: 2px solid $primary;
			  padding-bottom: 10px;
			  
			  @media (max-width: 767px) {
				  font-size: 2.5em;
			  }
			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
}


.features p {color: $wht;
			 font-weight: bold;
			  line-height: 35px;
			  font-size: 1.25em;


			   @media (max-width: 767px) {
				   text-align: center;
			   }
			   @media (max-width: 1024px) {
				   text-align: center;
			   }
}

.features h3 {color: $wht;
			  text-shadow: 2px 2px $primary;
			   margin-bottom: 10px;
			   font-size: 2em;

			   @media (max-width: 767px) {
				   font-size: 1.5em;
				   text-align: center;
			   }
			   @media (max-width: 1024px) {
				   font-size: 2em;
				   text-align: center;
			   }

}

.middle-feature img {width: 100%;
					 padding-top: 75px;
					 
			   @media (max-width: 767px) {
				   padding-top: 0px;
				   padding-bottom: 25px;
			   }
			   
			   @media (max-width: 1024px) and (orientation: portrait) {
				   padding-top: 0px !important;
			   }
			   @media (max-width: 1199px) {
				   width: 100%;
				   max-width: 500px;
				   display: block;
				   margin: 0 auto;
				   padding-top: 150px;
				   padding-bottom: 25px;
			   }
					 
					 
					 }


/* ------------- Why Choose Us -------------- */


.why-pure-true {padding: 50px 0px;
				border-bottom: 15px solid $secondary;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				
				@media (max-width: 767px) {
					text-align: center;
				}
				
				@media (max-width: 1024px) {
					text-align: center;
				}
}


.why-pure-true img {width: 100%;
					display: block;
					margin: 0 auto;
					min-width: 175px;
					padding-top: 50px;

					@media (max-width: 768px) {
						width: 80%;
					}
}

.why-pure-true h1 {color: $primary;
			  font-size: 4em;
			  border-bottom: 2px solid $primary;
			  padding-bottom: 15px;
			  text-align: center;
			  
			  @media (max-width: 767px) {
				  	font-size: 3em;

			  }
	}


.why-pure-true p {color: $secondary;
			 font-weight: bold;
			  line-height: 35px;
			  font-size: 1.25em;


			  @media (max-width: 767px) {
				  text-align: center;
			  }

}
.why-pure-true h2 {color: $primary;
			   margin-bottom: 10px;
			   font-size: 2em;
			
			   @media (max-width: 767px) {
				  text-align: center;
				  font-size: 2em !important;
			  }

			  @media (max-width: 1024px) {
				 font-size: 3em;
			  }
			 @media (max-width: 1199px) and (orientation: landscape) {
				  font-size: 1.5em;
			  }
	}


/* ------------- CTA -------------- */


.cta {padding: 50px 0px;
	  text-align: center;
	  		  background: url('../img/feature-banner.jpg');
		 	background-repeat: no-repeat;
		 	background-position: 100% 40%;
		 	background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			-webkit-background-size: cover;}

.cta img {max-width: 100px;
					display: block;
					margin: 0 auto;
					min-width: 100%;
					padding-top: 25px;
}

.cta h1 {color: $primary;
			  font-size: 4em;
			  border-bottom: 2px solid $primary;
			  padding-bottom: 15px;}


.cta p {color: $primary;
			 font-weight: bold;
			  line-height: 35px;
			  font-size: 1.25em;

}
.cta h2 {color: $primary;
			   margin-bottom: 10px;
			   font-size: 2em;
			}
.cta h3 {color: $secondary;
			   margin-bottom: 10px;
			   font-size: 2em;
			}



/* ------ FOOTER ------ */

footer {padding: 50px 5px 20px;}
